import {
  OPEN_LOGIN_MODAL,
  OPEN_REGISTER_MODAL,
  OPEN_REPLY_MODAL,
  CLOSE_MODALS,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  GET_MEMBER_INFO,
  GET_MEMBER_INFO_SUCCESS,
  GET_MEMBER_INFO_FAILED,
  GET_MEMBER_SWEETS,
  GET_MEMBER_SWEETS_SUCCESS,
  GET_MEMBER_SWEETS_FAILED,
} from "../constants/member";
import IMember from "../../types/member";
import ISweet from "../../types/sweet";
import { TMemberActions } from "../actions/member";

export type TMemberState = {
  member?: IMember;
  memberSweets: ISweet[];
  isLoggedIn: boolean;
  isMemberLoaded: boolean;
  loginModalOpen: boolean;
  registerModalOpen: boolean;
  replyModalOpen: boolean;
  registerRequest: boolean;
  registerSuccess: boolean;
  registerFailed: boolean;
  registerError?: string;
  loginRequest: boolean;
  loginSuccess: boolean;
  loginFailed: boolean;
  loginError?: string;
  getMemberRequest: boolean;
  memberSweetsRequest: boolean;
  memberSweetsSuccess: boolean;
  memberSweetsFailed: boolean;
};

const initialState: TMemberState = {
  member: undefined,
  memberSweets: [],
  isLoggedIn: false,
  isMemberLoaded: false,
  loginModalOpen: false,
  registerModalOpen: false,
  replyModalOpen: false,

  registerRequest: false,
  registerSuccess: false,
  registerFailed: false,
  registerError: undefined,

  loginRequest: false,
  loginSuccess: false,
  loginFailed: false,
  loginError: undefined,

  getMemberRequest: false,

  memberSweetsRequest: false,
  memberSweetsSuccess: false,
  memberSweetsFailed: false,
};

export const membersReducer = (
  state = initialState,
  action: TMemberActions
): TMemberState => {
  switch (action.type) {
    case REGISTER_REQUEST: {
      return {
        ...state,
        registerRequest: true,
      };
    }
    case REGISTER_SUCCESS: {
      return {
        ...state,
        registerFailed: false,
        registerRequest: false,
        registerSuccess: true,
        registerModalOpen: false,
        loginModalOpen: true,
        registerError: undefined,
      };
    }
    case REGISTER_FAILED: {
      return {
        ...state,
        registerFailed: true,
        registerRequest: false,
        registerError: action.err,
      };
    }
    case LOGIN_REQUEST: {
      return {
        ...state,
        loginRequest: true,
        registerSuccess: false,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        member: action.member,
        loginFailed: false,
        loginRequest: false,
        loginSuccess: true,
        isLoggedIn: true,
        loginError: undefined,
      };
    }
    case LOGIN_FAILED: {
      return {
        ...state,
        loginFailed: true,
        loginRequest: false,
        loginError: action.err,
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        member: undefined,
        memberSweets: [],
        isLoggedIn: false,
        loginSuccess: false,
      };
    }
    case GET_MEMBER_INFO: {
      return {
        ...state,
        getMemberRequest: true,
      };
    }
    case GET_MEMBER_INFO_SUCCESS: {
      return {
        ...state,
        member: action.member,
        getMemberRequest: false,
        isLoggedIn: true,
        isMemberLoaded: true,
      };
    }
    case GET_MEMBER_INFO_FAILED: {
      return {
        ...state,
        getMemberRequest: false,
        isMemberLoaded: true,
      };
    }
    case GET_MEMBER_SWEETS: {
      return {
        ...state,
        memberSweetsRequest: true,
      };
    }
    case GET_MEMBER_SWEETS_SUCCESS: {
      return {
        ...state,
        memberSweets: action.sweets,
        memberSweetsRequest: false,
        memberSweetsSuccess: true,
        memberSweetsFailed: false,
      };
    }
    case GET_MEMBER_SWEETS_FAILED: {
      return {
        ...state,
        memberSweetsRequest: false,
        memberSweetsFailed: true,
      };
    }
    case OPEN_LOGIN_MODAL: {
      return {
        ...state,
        loginModalOpen: true,
      };
    }
    case OPEN_REGISTER_MODAL: {
      return {
        ...state,
        registerModalOpen: true,
      };
    }
    case OPEN_REPLY_MODAL: {
      return {
        ...state,
        replyModalOpen: true,
      };
    }
    case CLOSE_MODALS: {
      return {
        ...state,
        loginModalOpen: false,
        registerModalOpen: false,
        replyModalOpen: false,
        loginSuccess: false,
        registerSuccess: false,
        registerError: undefined,
        loginError: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export const SWEETS_REQUEST: "SWEETS_REQUEST" = "SWEETS_REQUEST";
export const SWEETS_REQUEST_SUCCESS: "SWEETS_REQUEST_SUCCESS" =
  "SWEETS_REQUEST_SUCCESS";
export const SWEETS_REQUEST_FAILED: "SWEETS_REQUEST_FAILED" =
  "SWEETS_REQUEST_FAILED";

export const POST_SWEET_REQUEST: "POST_SWEET_REQUEST" = "POST_SWEET_REQUEST";
export const POST_SWEET_SUCCESS: "POST_SWEET_SUCCESS" = "POST_SWEET_SUCCESS";
export const POST_SWEET_FAILED: "POST_SWEET_FAILED" = "POST_SWEET_FAILED";

export const DELETE_SWEET_REQUEST: "DELETE_SWEET_REQUEST" =
  "DELETE_SWEET_REQUEST";
export const DELETE_SWEET_SUCCESS: "DELETE_SWEET_SUCCESS" =
  "DELETE_SWEET_SUCCESS";
export const DELETE_SWEET_FAILED: "DELETE_SWEET_FAILED" = "DELETE_SWEET_FAILED";

export const TOGGLE_STAR_SUCCESS: "TOGGLE_STAR_SUCCESS" = "TOGGLE_STAR_SUCCESS";

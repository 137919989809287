import { baseUrl, checkError, headers } from "./utils";
import IMember from "../types/member";
import ISweet from "../types/sweet";

export const getSweetsRequest = async () => {
  const res = await fetch(`${baseUrl}/sweets`, {
    headers: headers,
  });
  return checkError(res);
};

export const postSweetRequest = async (data: ISweet) => {
  const res = await fetch(`${baseUrl}/sweets`, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: JSON.stringify(data),
  });
  return checkError(res);
};

export const deleteSweetRequest = async (id: string) => {
  const res = await fetch(`${baseUrl}/sweets/${id}`, {
    method: "DELETE",
    headers: headers,
    credentials: "include",
  });
  return checkError(res);
};

export const toggleStarRequest = async (id: string, isLiked: boolean) => {
  const res = await fetch(`${baseUrl}/sweets/${id}/stars`, {
    method: isLiked ? "DELETE" : "PUT",
    headers: headers,
    credentials: "include",
  });
  return checkError(res);
};

export const getMemberInfoRequest = async () => {
  const res = await fetch(`${baseUrl}/members`, {
    headers: headers,
    credentials: "include",
  });
  return checkError(res);
};

export const getMemberSweetsRequest = async (id: string) => {
  const res = await fetch(`${baseUrl}/members/${id}/sweets`, {
    headers: headers,
    credentials: "include",
  });
  return checkError(res);
};

export const loginRequest = async (data: IMember) => {
  const res = await fetch(`${baseUrl}/auth/login`, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: JSON.stringify(data),
  });
  return checkError(res);
};

export const registerRequest = async (data: IMember) => {
  const res = await fetch(`${baseUrl}/auth/register`, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: JSON.stringify(data),
  });
  return checkError(res);
};

export const logoutRequest = async () => {
  const res = await fetch(`${baseUrl}/auth/logout`, {
    headers: headers,
    credentials: "include",
  });
  return checkError(res);
};

import { useCallback } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { Button } from "../";
import logo from "../../images/header-logo.png";
import { useDispatch, useSelector } from "../../services/hooks";
import {
  openLoginModal,
  openRegisterModal,
} from "../../services/actions/member";
import { logoutThunk } from "../../services/actions/member";
import { getGreetingTime } from "../../utils/utils";
import headerStyles from "./header.module.css";

function Header() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isLoggedIn, member } = useSelector((state) => state.member);

  const { t, i18n } = useTranslation();

  const onLoginClick = useCallback(() => {
    if (location.pathname === "/login") return;
    if (location.pathname === "/register") {
      history.replace({
        pathname: "/login",
      });
      return;
    }
    dispatch(openLoginModal());
    history.replace({
      pathname: "/login",
      state: { background: location },
    });
  }, [dispatch, history, location]);

  const onRegisterClick = useCallback(() => {
    if (location.pathname === "/register") return;
    if (location.pathname === "/login") {
      history.replace({
        pathname: "/register",
      });
      return;
    }
    dispatch(openRegisterModal());
    history.replace({
      pathname: "/register",
      state: { background: location },
    });
  }, [dispatch, history, location]);

  const onLogoutClick = useCallback(() => {
    dispatch(logoutThunk());
  }, [dispatch]);

  return (
    <header className={headerStyles.header}>
      <nav className={headerStyles.nav}>
        <ul className={headerStyles.navList}>
          <li className={headerStyles.navItemFlex}>
            <Link to="/">
              <img className={headerStyles.logo} src={logo} alt="Switter" />
            </Link>
            <NavLink
              to="/"
              className={headerStyles.navLink}
              activeClassName={headerStyles.navLinkActive}
            >
              {t("header.home")}
            </NavLink>
          </li>
          <li className={cn(headerStyles.navItem, headerStyles.navItemFlex)}>
            {isLoggedIn ? (
              <>
                <NavLink
                  to="/profile"
                  className={headerStyles.navLink}
                  activeClassName={headerStyles.navLinkActive}
                >
                  {t("header.date", { context: getGreetingTime() })}{" "}
                  {member?.name}
                </NavLink>
                <Button
                  type="button"
                  className={headerStyles.navLink}
                  onClick={onLogoutClick}
                >
                  {t("header.logout")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  className={headerStyles.navLink}
                  onClick={onLoginClick}
                >
                  {t("header.login")}
                </Button>
                <Button
                  type="button"
                  className={headerStyles.navLink}
                  onClick={onRegisterClick}
                >
                  {t("header.register")}
                </Button>
              </>
            )}
            <div>
              <Button
                className={headerStyles.lang}
                type="submit"
                onClick={() => i18n.changeLanguage("en")}
              >
                EN
              </Button>
              <Button
                className={headerStyles.lang}
                type="submit"
                onClick={() => i18n.changeLanguage("ru")}
              >
                RU
              </Button>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;

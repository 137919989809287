import { useEffect } from "react";
import { ProfileInfo, SweetsList, Preloader } from "../components";
import { useSelector, useDispatch } from "../services/hooks";
import { getMemberSweetsThunk } from "../services/actions/member";
import styles from "./pages.module.css";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { member, memberSweets, memberSweetsRequest } = useSelector(
    (state) => state.member
  );
  const { sweets } = useSelector((state) => state.sweets);

  useEffect(() => {
    dispatch(getMemberSweetsThunk(member?._id));
  }, [dispatch, member?._id, sweets]);

  return (
    <section className={styles.section}>
      <ProfileInfo member={member} />
      {memberSweetsRequest ? (
        <Preloader pageLoader />
      ) : (
        <SweetsList data={memberSweets} />
      )}
    </section>
  );
};

export default ProfilePage;

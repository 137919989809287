const en = {
  translation: {
    header: {
      home: "Home",
      profile: "Profile",
      login: "Login",
      logout: "Logout",
      register: "Register",
      date_morning: "Good morning, ",
      date_afternoon: "Good afternoon, ",
      date_evening: "Good evening, ",
      date_night: "Good night, ",
    },
    error: {
      serverError: "Internal Server Error",
    },
    forms: {
      signIn: "Sign In",
      signUp: "Sign Up",
      password: "Password",
      postSweetPh: "Type to start sweeting",
      post: "Post",
      name: "Name",
      avatarUrl: "Link to avatar (optional)",
    },
    profile: {
      email: "E-mail Address:",
      regDate: "Registration Date:",
      date: "{{date, DATE_HUGE}}",
    },
  },
};

export default en;

import { useEffect, FormEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Input } from "../";
import { useDispatch, useSelector } from "../../services/hooks";
import { registerThunk } from "../../services/actions/member";
import IRegisterPageProps from "./register.types";
import { openLoginModal } from "../../services/actions/member";

const Register = ({ validation }: IRegisterPageProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { values, errors, isValid, resetForm, setErrors } = validation;
  const { isLoggedIn, registerSuccess, registerError } = useSelector(
    (state) => state.member
  );

  // Initial reset
  useEffect(() => {
    resetForm();
    return () => resetForm();
  }, [resetForm]);

  // Protect from logged users
  useEffect(() => {
    if (registerSuccess) {
      history.replace({
        pathname: "/",
        state: { background: undefined },
      });
      dispatch(openLoginModal());
    }
    if (isLoggedIn) {
      history.push("/");
    }
  }, [dispatch, history, isLoggedIn, location, registerSuccess]);

  // Set form error
  useEffect(() => {
    if (registerError) {
      setErrors({
        submit:
          typeof registerError === "object"
            ? t("error.serverError")
            : registerError,
      });
    }
    return () => setErrors({});
  }, [registerError, setErrors, t]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(
      registerThunk({
        email: values.email,
        password: values.password,
        name: values.name,
        avatarUrl: values.avatarUrl,
      })
    );
  };

  return (
    <Form
      name="login"
      onSubmit={handleSubmit}
      title={t("forms.signUp")}
      isValid={isValid}
      errors={errors}
    >
      <Input
        validation={validation}
        name="name"
        placeholder={t("forms.name")}
        type="text"
        required
        autoFocus
        minLength={2}
        maxLength={30}
      />
      <Input
        validation={validation}
        name="email"
        placeholder="Email"
        autoComplete="email"
        type="email"
        required
      />
      <Input
        validation={validation}
        name="password"
        placeholder={t("forms.password")}
        type="password"
        required
        minLength={4}
        autoComplete="current-password"
        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
      />
      <Input
        validation={validation}
        name="avatarUrl"
        placeholder={t("forms.avatarUrl")}
        type="url"
      />
    </Form>
  );
};

export default Register;

import { useEffect } from "react";
import { SweetsList, PostSweetForm } from "../components";
import { useSelector, useDispatch } from "../services/hooks";
import { getSweetsThunk } from "../services/actions/sweets";
import ISweet from "../types/sweet";
import styles from "./pages.module.css";

interface IHomePageProps {
  data: ISweet[];
  validation: any;
}

const HomePage = ({ data, validation }: IHomePageProps) => {
  const { isLoggedIn } = useSelector((state) => state.member);
  const dispatch = useDispatch();

  //TODO Implement WebSockets
  useEffect(() => {
    const timerID = setInterval(() => {
      dispatch(getSweetsThunk());
    }, 60000);
    return () => clearInterval(timerID);
  }, [dispatch]);

  return (
    <section className={styles.section}>
      {isLoggedIn && <PostSweetForm validation={validation} />}
      <SweetsList data={data} />
    </section>
  );
};

export default HomePage;

import cn from "classnames";
import IModalOverlayProps from "./modal-overlay.types";
import modalOverlayStyles from "./modal-overlay.module.css";

const ModalOverlay = ({ children, onOverlayClick }: IModalOverlayProps) => {
  return (
    <section className={cn(modalOverlayStyles.modal)} onClick={onOverlayClick}>
      {children}
    </section>
  );
};

export default ModalOverlay;

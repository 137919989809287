import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../services/hooks";
import { Button } from "../";
import { toggleMenu } from "../../services/actions/header";
import { logoutThunk } from "../../services/actions/member";
import { getGreetingTime } from "../../utils/utils";
import headerStyles from "./header.module.css";

function HeaderMobile() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { isLoggedIn, member } = useSelector((state) => state.member);
  const { mobileMenuOpen } = useSelector((state) => state.header);

  const handleToggleMenu = useCallback(() => {
    dispatch(toggleMenu());
  }, [dispatch]);

  const handleLinkClick = useCallback(
    (to) => {
      dispatch(toggleMenu());
      history.replace({
        pathname: to,
      });
    },
    [dispatch, history]
  );

  const onLogoutClick = useCallback(() => {
    dispatch(toggleMenu());
    dispatch(logoutThunk());
  }, [dispatch]);

  return (
    <header
      className={cn(headerStyles.header, {
        [headerStyles.headerOpened]: mobileMenuOpen,
      })}
    >
      <nav className={headerStyles.nav}>
        <div>
          <Button
            className={headerStyles.lang}
            type="submit"
            onClick={() => i18n.changeLanguage("en")}
          >
            EN
          </Button>
          <Button
            className={headerStyles.lang}
            type="submit"
            onClick={() => i18n.changeLanguage("ru")}
          >
            RU
          </Button>
        </div>
        {mobileMenuOpen ? (
          <>
            <ul
              className={cn(headerStyles.navList, {
                [headerStyles.navListOpen]: mobileMenuOpen,
              })}
            >
              {isLoggedIn ? (
                <>
                  <p className={headerStyles.text}>
                    {" "}
                    {t("header.date", { context: getGreetingTime() })}{" "}
                    {member?.name}
                  </p>
                  <Button
                    type="button"
                    className={headerStyles.navLink}
                    onClick={() => handleLinkClick("/")}
                  >
                    {t("header.home")}
                  </Button>
                  <Button
                    type="button"
                    className={headerStyles.navLink}
                    onClick={() => handleLinkClick("/profile")}
                  >
                    {t("header.profile")}
                  </Button>
                  <Button
                    type="button"
                    className={headerStyles.navLink}
                    onClick={onLogoutClick}
                  >
                    {t("header.logout")}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="button"
                    className={headerStyles.navLink}
                    onClick={() => handleLinkClick("/")}
                  >
                    {t("header.home")}
                  </Button>
                  <Button
                    type="button"
                    className={headerStyles.navLink}
                    onClick={() => handleLinkClick("/login")}
                  >
                    {t("header.login")}
                  </Button>
                  <Button
                    type="button"
                    className={headerStyles.navLink}
                    onClick={() => handleLinkClick("/register")}
                  >
                    {t("header.register")}
                  </Button>
                </>
              )}
            </ul>
            <Button
              className={headerStyles.closeButton}
              type="button"
              onClick={handleToggleMenu}
            />
          </>
        ) : (
          <div className={headerStyles.iconContainer}>
            <Button
              className={headerStyles.menuIcon}
              type="button"
              onClick={handleToggleMenu}
            ></Button>
            <div className={headerStyles.iconArrow}></div>
          </div>
        )}
      </nav>
    </header>
  );
}

export default HeaderMobile;

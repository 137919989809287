import { useEffect, FormEvent } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Input } from "../";
import { useDispatch, useSelector } from "../../services/hooks";
import ILoginProps from "./login.types";
import { loginThunk } from "../../services/actions/member";

const Login = ({ validation }: ILoginProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { values, errors, isValid, resetForm, setErrors } = validation;
  const { isLoggedIn, loginSuccess, loginError } = useSelector(
    (state) => state.member
  );

  // Initial reset
  useEffect(() => {
    resetForm();
    return () => resetForm();
  }, [resetForm]);

  // Protect from logged users
  useEffect(() => {
    if (loginSuccess || isLoggedIn) {
      history.push("/");
    }
  }, [history, isLoggedIn, loginSuccess]);

  // Set form error
  useEffect(() => {
    if (loginError) {
      setErrors({
        submit:
          typeof loginError === "object" ? t("error.serverError") : loginError,
      });
    }
    return () => setErrors({});
  }, [loginError, setErrors, t]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(
      loginThunk({
        email: values.email,
        password: values.password,
      })
    );
  };

  return (
    <Form
      name="login"
      onSubmit={handleSubmit}
      title={t("forms.signIn")}
      isValid={isValid}
      errors={errors}
    >
      <Input
        validation={validation}
        name="email"
        placeholder="Email"
        autoComplete="email"
        autoFocus
        type="email"
        required
      />
      <Input
        validation={validation}
        name="password"
        placeholder={t("forms.password")}
        type="password"
        required
        minLength={4}
        autoComplete="current-password"
        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
      />
    </Form>
  );
};

export default Login;

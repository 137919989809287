import {
  OPEN_LOGIN_MODAL,
  OPEN_REGISTER_MODAL,
  OPEN_REPLY_MODAL,
  CLOSE_MODALS,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  GET_MEMBER_INFO,
  GET_MEMBER_INFO_SUCCESS,
  GET_MEMBER_INFO_FAILED,
  GET_MEMBER_SWEETS,
  GET_MEMBER_SWEETS_SUCCESS,
  GET_MEMBER_SWEETS_FAILED,
} from "../constants/member";
import IMember from "../../types/member";
import ISweet from "../../types/sweet";
import { AppThunk, AppDispatch } from "../types";
import {
  loginRequest,
  registerRequest,
  logoutRequest,
  getMemberInfoRequest,
  getMemberSweetsRequest,
} from "../../utils/api";

export interface IOpenLoginModal {
  readonly type: typeof OPEN_LOGIN_MODAL;
}

export interface IOpenRegisterModal {
  readonly type: typeof OPEN_REGISTER_MODAL;
}

export interface IOpenReplyModal {
  readonly type: typeof OPEN_REPLY_MODAL;
}

export interface ICloseModals {
  readonly type: typeof CLOSE_MODALS;
}

export interface ILoginAction {
  readonly type: typeof LOGIN_REQUEST;
}

export interface ILoginFailedAction {
  readonly type: typeof LOGIN_FAILED;
  readonly err: string;
}

export interface ILoginSuccessAction {
  readonly type: typeof LOGIN_SUCCESS;
  readonly member: IMember;
}

export interface IRegisterAction {
  readonly type: typeof REGISTER_REQUEST;
}

export interface IRegisterFailedAction {
  readonly type: typeof REGISTER_FAILED;
  readonly err: string;
}

export interface IRegisterSuccessAction {
  readonly type: typeof REGISTER_SUCCESS;
}

export interface ILogoutSuccessAction {
  readonly type: typeof LOGOUT_SUCCESS;
}

export interface IGetMemberInfoAction {
  readonly type: typeof GET_MEMBER_INFO;
}

export interface IGetMemberInfoSuccessAction {
  readonly type: typeof GET_MEMBER_INFO_SUCCESS;
  readonly member: IMember;
}

export interface IGetMemberInfoFailedAction {
  readonly type: typeof GET_MEMBER_INFO_FAILED;
}

export interface IGetMemberSweetsAction {
  readonly type: typeof GET_MEMBER_SWEETS;
}

export interface IGetMemberSweetsSuccessAction {
  readonly type: typeof GET_MEMBER_SWEETS_SUCCESS;
  readonly sweets: ISweet[];
}

export interface IGetMemberSweetsFailedAction {
  readonly type: typeof GET_MEMBER_SWEETS_FAILED;
}

export type TMemberActions =
  | IOpenLoginModal
  | IOpenRegisterModal
  | IOpenReplyModal
  | ICloseModals
  | ILoginAction
  | ILoginSuccessAction
  | ILoginFailedAction
  | ILogoutSuccessAction
  | IRegisterAction
  | IRegisterSuccessAction
  | IRegisterFailedAction
  | IGetMemberInfoAction
  | IGetMemberInfoSuccessAction
  | IGetMemberInfoFailedAction
  | IGetMemberSweetsAction
  | IGetMemberSweetsSuccessAction
  | IGetMemberSweetsFailedAction;

export const openLoginModal = (): IOpenLoginModal => ({
  type: OPEN_LOGIN_MODAL,
});

export const openRegisterModal = (): IOpenRegisterModal => ({
  type: OPEN_REGISTER_MODAL,
});

export const openReplyModal = (): IOpenReplyModal => ({
  type: OPEN_REPLY_MODAL,
});

export const closeModals = (): ICloseModals => ({
  type: CLOSE_MODALS,
});

export const loginAction = (): ILoginAction => ({
  type: LOGIN_REQUEST,
});

export const loginFailedAction = (err: string): ILoginFailedAction => ({
  type: LOGIN_FAILED,
  err,
});

export const loginSuccessAction = (member: IMember): ILoginSuccessAction => ({
  type: LOGIN_SUCCESS,
  member,
});

export const registerAction = (): IRegisterAction => ({
  type: REGISTER_REQUEST,
});

export const registerFailedAction = (err: string): IRegisterFailedAction => ({
  type: REGISTER_FAILED,
  err,
});

export const registerSuccessAction = (): IRegisterSuccessAction => ({
  type: REGISTER_SUCCESS,
});

export const logoutSuccessAction = (): ILogoutSuccessAction => ({
  type: LOGOUT_SUCCESS,
});

export const getMemberInfoAction = (): IGetMemberInfoAction => ({
  type: GET_MEMBER_INFO,
});

export const getMemberInfoSuccessAction = (
  member: IMember
): IGetMemberInfoSuccessAction => ({
  type: GET_MEMBER_INFO_SUCCESS,
  member,
});

export const getMemberInfoFailedAction = (): IGetMemberInfoFailedAction => ({
  type: GET_MEMBER_INFO_FAILED,
});

export const getMemberSweetsAction = (): IGetMemberSweetsAction => ({
  type: GET_MEMBER_SWEETS,
});

export const getMemberSweetsSuccessAction = (
  sweets: ISweet[]
): IGetMemberSweetsSuccessAction => ({
  type: GET_MEMBER_SWEETS_SUCCESS,
  sweets,
});

export const getMemberSweetsFailedAction =
  (): IGetMemberSweetsFailedAction => ({
    type: GET_MEMBER_SWEETS_FAILED,
  });

export const loginThunk: AppThunk =
  (formData: IMember) => (AppDispatch: AppDispatch) => {
    AppDispatch(loginAction());
    loginRequest(formData)
      .then((data) => {
        AppDispatch(loginSuccessAction(data));
        AppDispatch(closeModals());
      })
      .catch((err) => {
        console.error(err);
        AppDispatch(loginFailedAction(err));
      });
  };

export const registerThunk: AppThunk =
  (formData: IMember) => (AppDispatch: AppDispatch) => {
    AppDispatch(registerAction());
    registerRequest(formData)
      .then(() => {
        AppDispatch(registerSuccessAction());
      })
      .catch((err) => {
        console.error(err);
        AppDispatch(registerFailedAction(err));
      });
  };

export const logoutThunk: AppThunk = () => (AppDispatch: AppDispatch) => {
  logoutRequest()
    .then(() => {
      AppDispatch(logoutSuccessAction());
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getMemberInfoThunk: AppThunk =
  () => (AppDispatch: AppDispatch) => {
    AppDispatch(getMemberInfoAction());
    getMemberInfoRequest()
      .then((member) => {
        AppDispatch(getMemberInfoSuccessAction(member));
      })
      .catch((err) => {
        console.error(err);
        AppDispatch(getMemberInfoFailedAction());
      });
  };

export const getMemberSweetsThunk: AppThunk =
  (id: string) => (AppDispatch: AppDispatch) => {
    AppDispatch(getMemberSweetsAction());
    getMemberSweetsRequest(id)
      .then((sweets) => {
        AppDispatch(getMemberSweetsSuccessAction(sweets));
      })
      .catch((err) => {
        console.error(err);
        AppDispatch(getMemberSweetsFailedAction());
      });
  };

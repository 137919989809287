import { useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import {
  Router,
  Route,
  Switch,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom";

import useWindowSize from "../../hooks/useWindowSize";
import useValidation from "../../hooks/useValidation";
import {
  Header,
  HeaderMobile,
  Modal,
  Login,
  Register,
  Preloader,
  ProtectedRoute,
  PostSweetForm,
} from "../";
import { HomePage, LoginPage, RegisterPage, ProfilePage } from "../../pages";
import { useSelector, useDispatch } from "../../services/hooks";
import { getSweetsThunk } from "../../services/actions/sweets";
import { getMemberInfoThunk } from "../../services/actions/member";
import styles from "./app.module.css";

function App() {
  const history = useHistory();
  const size = useWindowSize();
  const dispatch = useDispatch();
  const validation = useValidation();
  const location = useLocation<{
    background: undefined;
  }>();

  const {
    loginModalOpen,
    registerModalOpen,
    replyModalOpen,
    getMemberRequest,
  } = useSelector((state) => state.member);
  const { sweets } = useSelector((state) => state.sweets);

  // For opening popups by a click and pages by a direct link
  const background = location.state && location.state.background;

  // Classes for CSS transition
  const transitionClasses = {
    enterActive: styles.modalEnterActive,
    enterDone: styles.modalEnterDone,
    exit: styles.modalExit,
    exitActive: styles.modalExitActive,
  };

  // Initial requests
  useEffect(() => {
    dispatch(getSweetsThunk());
    dispatch(getMemberInfoThunk());
  }, [dispatch]);

  // reset background history
  useEffect(() => {
    history.replace({
      state: { background: undefined },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (getMemberRequest) {
    return <Preloader pageLoader />;
  }

  return (
    <>
      <Router history={history}>
        {size.width > 750 ? <Header /> : <HeaderMobile />}
        <main className={styles.main}>
          <Switch location={background || location}>
            <Route exact path="/">
              <HomePage data={sweets} validation={validation} />
            </Route>
            <Route exact path="/login">
              <LoginPage validation={validation} />
            </Route>
            <Route exact path="/register">
              <RegisterPage validation={validation} />
            </Route>
            <ProtectedRoute path="/profile">
              <ProfilePage />
            </ProtectedRoute>
            <Route>
              <Redirect to={"/"} />
            </Route>
          </Switch>
        </main>
      </Router>
      <CSSTransition
        in={loginModalOpen}
        classNames={transitionClasses}
        unmountOnExit
        timeout={300}
      >
        <Modal>
          <Login validation={validation} />
        </Modal>
      </CSSTransition>
      <CSSTransition
        in={registerModalOpen}
        classNames={transitionClasses}
        unmountOnExit
        timeout={300}
      >
        <Modal>
          <Register validation={validation} />
        </Modal>
      </CSSTransition>
      <CSSTransition
        in={replyModalOpen}
        classNames={transitionClasses}
        unmountOnExit
        timeout={300}
      >
        <Modal>
          <PostSweetForm validation={validation} isModal />
        </Modal>
      </CSSTransition>
    </>
  );
}

export default App;

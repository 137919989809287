import { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";

import { closeModals } from "../../services/actions/member";
import { ModalOverlay, Button } from "..";
import IModalProps from "./modal.types";
import modalStyles from "./modal.module.css";

const modalRoot = document.getElementById("react-modals")!;

const Modal = ({ children }: IModalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClose = useCallback(() => {
    dispatch(closeModals());
    history.push("/");
  }, [dispatch, history]);

  useEffect(() => {
    function closeModalByEsc(e: KeyboardEvent) {
      if (e.key === "Escape") {
        handleClose();
      }
    }

    document.addEventListener("keydown", closeModalByEsc);

    return () => {
      document.removeEventListener("keydown", closeModalByEsc);
    };
  }, [handleClose]);

  const handleOverlayClick = useCallback(
    (e) => {
      if (e.target === e.currentTarget) {
        handleClose();
      }
    },
    [handleClose]
  );

  return ReactDOM.createPortal(
    <ModalOverlay onOverlayClick={handleOverlayClick}>
      <div className={modalStyles.modalContainer}>
        {children}
        <Button
          className={modalStyles.closeButton}
          type="button"
          onClick={handleClose}
        />
      </div>
    </ModalOverlay>,
    modalRoot
  );
};

export default Modal;

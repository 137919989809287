export const baseUrl = "https://api.switter.ner.works";
export const headers = {
  "Content-Type": "application/json",
};
export const defaultAvatar =
  "https://images.vexels.com/media/users/3/183030/isolated/lists/d2c8fc2b3ef54b9902a5e197b4d18686-yellow-sweater-icon.png";

export const checkError = async (res: Response) => {
  if (res.ok) {
    return res.json();
  }
  await res
    .text()
    .then((text) => {
      try {
        return JSON.parse(text);
      } catch {
        return text;
      }
    })
    .then((text) => {
      return Promise.reject(text.message || text.error || text);
    });
};

export const getGreetingTime = () => {
  const currentHour = new Date().getHours();
  const split_morning = 6;
  const split_afternoon = 12;
  const split_evening = 17;
  const split_night = 23;

  if (currentHour >= split_morning && currentHour <= split_afternoon) {
    return "morning";
  } else if (currentHour >= split_afternoon && currentHour <= split_evening) {
    return "afternoon";
  } else if (currentHour >= split_evening && currentHour <= split_night) {
    return "evening";
  }
  return "night";
};

import { Login } from "../components";
import styles from "./pages.module.css";

interface ILoginPageProps {
  validation: any;
}

const LoginPage = ({ validation }: ILoginPageProps) => {
  return (
    <section className={styles.authSection}>
      <Login validation={validation} />
    </section>
  );
};

export default LoginPage;

import cn from "classnames";
import TButtonProps from "./button.types";
import buttonStyles from "./button.module.css";

const Button = ({
  className,
  children,
  type,
  onClick,
  ...props
}: TButtonProps) => {
  return (
    <button
      className={cn(buttonStyles.button, className)}
      type={type}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;

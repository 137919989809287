export const OPEN_LOGIN_MODAL: "OPEN_LOGIN_MODAL" = "OPEN_LOGIN_MODAL";
export const OPEN_REGISTER_MODAL: "OPEN_REGISTER_MODAL" = "OPEN_REGISTER_MODAL";
export const OPEN_REPLY_MODAL: "OPEN_REPLY_MODAL" = "OPEN_REPLY_MODAL";
export const CLOSE_MODALS: "CLOSE_MODALS" = "CLOSE_MODALS";

export const REGISTER_REQUEST: "REGISTER_REQUEST" = "REGISTER_REQUEST";
export const REGISTER_SUCCESS: "REGISTER_SUCCESS" = "REGISTER_SUCCESS";
export const REGISTER_FAILED: "REGISTER_FAILED" = "REGISTER_FAILED";

export const LOGIN_REQUEST: "LOGIN_REQUEST" = "LOGIN_REQUEST";
export const LOGIN_SUCCESS: "LOGIN_SUCCESS" = "LOGIN_SUCCESS";
export const LOGIN_FAILED: "LOGIN_FAILED" = "LOGIN_FAILED";

export const LOGOUT_SUCCESS: "LOGOUT_SUCCESS" = "LOGOUT_SUCCESS";

export const GET_MEMBER_INFO: "GET_MEMBER_INFO" = "GET_MEMBER_INFO";
export const GET_MEMBER_INFO_SUCCESS: "GET_MEMBER_INFO_SUCCESS" =
  "GET_MEMBER_INFO_SUCCESS";
export const GET_MEMBER_INFO_FAILED: "GET_MEMBER_INFO_FAILED" =
  "GET_MEMBER_INFO_FAILED";

export const GET_MEMBER_SWEETS: "GET_MEMBER_SWEETS" = "GET_MEMBER_SWEETS";
export const GET_MEMBER_SWEETS_SUCCESS: "GET_MEMBER_SWEETS_SUCCESS" =
  "GET_MEMBER_SWEETS_SUCCESS";
export const GET_MEMBER_SWEETS_FAILED: "GET_MEMBER_SWEETS_FAILED" =
  "GET_MEMBER_SWEETS_FAILED";

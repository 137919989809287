import { Register } from "../components";
import styles from "./pages.module.css";

interface IRegisterPageProps {
  validation: any;
}

const RegisterPage = ({ validation }: IRegisterPageProps) => {
  return (
    <section className={styles.authSection}>
      <Register validation={validation} />
    </section>
  );
};

export default RegisterPage;

import { useTranslation } from "react-i18next";
import IProfileInfoProps from "./profile-info.types";
import profileStyles from "./profile-info.module.css";

const ProfileInfo = ({ member }: IProfileInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className={profileStyles.profile}>
      <div className={profileStyles.info}>
        <h3 className={profileStyles.name}>{member?.name}</h3>
        <p className={profileStyles.text}>
          <span className={profileStyles.textBold}>{t("profile.email")}</span>{" "}
          {member?.email}
        </p>
        <p className={profileStyles.text}>
          <span className={profileStyles.textBold}>{t("profile.regDate")}</span>{" "}
          {member?.registrationDate &&
            t("profile.date", {
              date: new Date(member?.registrationDate),
            })}{" "}
        </p>
      </div>
      <img
        className={profileStyles.avatar}
        src={member?.avatarUrl}
        alt={member?.name}
      />
    </div>
  );
};

export default ProfileInfo;

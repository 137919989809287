import { useEffect, useState, useCallback } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { Button, Preloader } from "../";
import { useSelector, useDispatch } from "../../services/hooks";
import { openLoginModal, openReplyModal } from "../../services/actions/member";
import {
  toggleStarThunk,
  deleteSweetThunk,
} from "../../services/actions/sweets";
import { defaultAvatar } from "../../utils/utils";
import ISweetProps from "./sweet.types";
import sweetStyles from "./sweet.module.css";

import { ReactComponent as DeleteIcon } from "../../images/sweet-delete.svg";
import { ReactComponent as StarIcon } from "../../images/sweet-star.svg";
import { ReactComponent as ReplyIcon } from "../../images/sweet-reply.svg";

const Sweet = ({ item }: ISweetProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { member, isLoggedIn } = useSelector((state) => state.member);
  const { deleteRequest, deletingSweet } = useSelector((state) => state.sweets);
  const [isOwn, setIsOwn] = useState<boolean>(false);
  const [isLiked, setIsLiked] = useState<boolean>(false);

  // Check owner and star status
  useEffect(() => {
    setIsOwn(item.owner._id === member?._id);
    setIsLiked(item.stars.some((id) => id === member?._id));
  }, [item.owner._id, item.stars, member]);

  const handleOpenLoginModal = useCallback(() => {
    dispatch(openLoginModal());
  }, [dispatch]);

  const handleToggleStar = useCallback(() => {
    dispatch(toggleStarThunk(item._id, isLiked));
  }, [dispatch, isLiked, item._id]);

  const handleDelete = useCallback(() => {
    dispatch(deleteSweetThunk(item._id));
  }, [dispatch, item._id]);

  const handleReply = useCallback(() => {
    dispatch(openReplyModal());
  }, [dispatch]);

  return (
    <article className={sweetStyles.card}>
      <img
        className={sweetStyles.avatar}
        src={item.owner?.avatarUrl || defaultAvatar}
        alt={item.owner?.name}
      />
      <div className={sweetStyles.textContainer}>
        <h3 className={sweetStyles.title}>{item.owner?.name}</h3>
        <p className={sweetStyles.text}>{item.text}</p>
      </div>
      <div className={sweetStyles.info}>
        <span className={sweetStyles.date}>
          {t("profile.date", { date: new Date(item.postDate) })}
        </span>
        {isOwn && (
          <Button
            type="button"
            className={sweetStyles.deleteButton}
            onClick={handleDelete}
          >
            {deleteRequest && deletingSweet === item._id ? (
              <Preloader />
            ) : (
              <DeleteIcon className={cn(sweetStyles.svg, sweetStyles.delete)} />
            )}
          </Button>
        )}
        {isLoggedIn ? (
          <>
            <Button
              type="button"
              className={sweetStyles.replyButton}
              onClick={handleReply}
            >
              <ReplyIcon className={cn(sweetStyles.svg, sweetStyles.reply)} />
            </Button>
            <Button type="button" className={sweetStyles.starButton}>
              <StarIcon
                className={cn(sweetStyles.svg, sweetStyles.star, {
                  [sweetStyles.goldStar]: isLiked,
                })}
                onClick={handleToggleStar}
              />
            </Button>
          </>
        ) : (
          <>
            <Button type="button" className={sweetStyles.starButton}>
              <StarIcon
                className={cn(sweetStyles.svg, sweetStyles.star)}
                onClick={handleOpenLoginModal}
              />
            </Button>
            <span className={sweetStyles.starTip}>Sign in to put a star</span>
          </>
        )}
        <span className={sweetStyles.count}>{item.stars.length}</span>
      </div>
    </article>
  );
};

export default Sweet;

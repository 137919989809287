import {
  SWEETS_REQUEST,
  SWEETS_REQUEST_SUCCESS,
  SWEETS_REQUEST_FAILED,
  POST_SWEET_REQUEST,
  POST_SWEET_SUCCESS,
  POST_SWEET_FAILED,
  DELETE_SWEET_REQUEST,
  DELETE_SWEET_SUCCESS,
  DELETE_SWEET_FAILED,
  TOGGLE_STAR_SUCCESS,
} from "../constants/sweets";
import ISweet from "../../types/sweet";
import { AppThunk, AppDispatch } from "../types";
import { closeModals } from "../../services/actions/member";
import {
  getSweetsRequest,
  postSweetRequest,
  deleteSweetRequest,
  toggleStarRequest,
} from "../../utils/api";

export interface IGetSweetsAction {
  readonly type: typeof SWEETS_REQUEST;
}

export interface IGetSweetsSuccessAction {
  readonly type: typeof SWEETS_REQUEST_SUCCESS;
  readonly sweets: ISweet[];
}

export interface IGetSweetsFailedAction {
  readonly type: typeof SWEETS_REQUEST_FAILED;
}

export interface IPostSweetAction {
  readonly type: typeof POST_SWEET_REQUEST;
}

export interface IPostSweetSuccessAction {
  readonly type: typeof POST_SWEET_SUCCESS;
  readonly sweet: ISweet;
}

export interface IPostSweetFailedAction {
  readonly type: typeof POST_SWEET_FAILED;
}

export interface IDeleteSweetRequestAction {
  readonly type: typeof DELETE_SWEET_REQUEST;
  readonly id: string;
}

export interface IDeleteSweetSuccessAction {
  readonly type: typeof DELETE_SWEET_SUCCESS;
  readonly id: string;
}

export interface IDeleteSweetFailedAction {
  readonly type: typeof DELETE_SWEET_FAILED;
}

export interface IToggleStarSuccessAction {
  readonly type: typeof TOGGLE_STAR_SUCCESS;
  readonly sweet: ISweet;
}

export type TSweetsActions =
  | IGetSweetsAction
  | IGetSweetsSuccessAction
  | IGetSweetsFailedAction
  | IPostSweetAction
  | IPostSweetSuccessAction
  | IPostSweetFailedAction
  | IDeleteSweetRequestAction
  | IDeleteSweetSuccessAction
  | IDeleteSweetFailedAction
  | IToggleStarSuccessAction;

export const getSweetsAction = (): IGetSweetsAction => ({
  type: SWEETS_REQUEST,
});

export const getSweetsSuccessAction = (
  sweets: ISweet[]
): IGetSweetsSuccessAction => ({
  type: SWEETS_REQUEST_SUCCESS,
  sweets,
});

export const getSweetsFailedAction = (): IGetSweetsFailedAction => ({
  type: SWEETS_REQUEST_FAILED,
});

export const postSweetAction = (): IPostSweetAction => ({
  type: POST_SWEET_REQUEST,
});

export const postSweetSuccessAction = (
  sweet: ISweet
): IPostSweetSuccessAction => ({
  type: POST_SWEET_SUCCESS,
  sweet,
});

export const postSweetFailedAction = (): IPostSweetFailedAction => ({
  type: POST_SWEET_FAILED,
});

export const deleteSweetRequestAction = (
  id: string
): IDeleteSweetRequestAction => ({
  type: DELETE_SWEET_REQUEST,
  id,
});

export const deleteSweetSuccessAction = (
  id: string
): IDeleteSweetSuccessAction => ({
  type: DELETE_SWEET_SUCCESS,
  id,
});

export const deleteSweetFailedAction = (): IDeleteSweetFailedAction => ({
  type: DELETE_SWEET_FAILED,
});

export const toggleStarSuccessAction = (
  sweet: ISweet
): IToggleStarSuccessAction => ({
  type: TOGGLE_STAR_SUCCESS,
  sweet,
});

export const getSweetsThunk: AppThunk = () => (AppDispatch: AppDispatch) => {
  AppDispatch(getSweetsAction());
  getSweetsRequest()
    .then((sweets) => {
      AppDispatch(getSweetsSuccessAction(sweets));
    })
    .catch((err) => {
      console.error(err);
      AppDispatch(getSweetsFailedAction());
    });
};

export const postSweetThunk: AppThunk =
  (sweet: ISweet) => (AppDispatch: AppDispatch) => {
    AppDispatch(postSweetAction());
    postSweetRequest(sweet)
      .then((sweet) => {
        AppDispatch(postSweetSuccessAction(sweet));
        AppDispatch(closeModals());
      })
      .catch((err) => {
        console.error(err);
        AppDispatch(postSweetFailedAction());
      });
  };

export const deleteSweetThunk: AppThunk =
  (id) => (AppDispatch: AppDispatch) => {
    AppDispatch(deleteSweetRequestAction(id));
    deleteSweetRequest(id)
      .then((deleted) => {
        AppDispatch(deleteSweetSuccessAction(deleted._id));
      })
      .catch((err) => {
        console.error(err);
        AppDispatch(deleteSweetFailedAction());
      });
  };

export const toggleStarThunk: AppThunk =
  (id, isLiked) => (AppDispatch: AppDispatch) => {
    toggleStarRequest(id, isLiked)
      .then((sweet) => {
        AppDispatch(toggleStarSuccessAction(sweet));
      })
      .catch((err) => {
        console.error(err);
      });
  };

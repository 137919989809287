import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { DateTime } from "luxon";
import en from "./locales/en";
import ru from "./locales/ru";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (value instanceof Date) {
          return DateTime.fromJSDate(value)
            .setLocale(lng!)
            .toLocaleString(DateTime.DATE_FULL);
        }
        return value;
      },
    },
    resources: {
      en: en,
      ru: ru,
    },
  });

export default i18n;

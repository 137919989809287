const ru = {
  translation: {
    header: {
      home: "Главная",
      profile: "Профиль",
      login: "Вход",
      logout: "Выйти",
      register: "Регистрация",
      date_morning: "Доброе утро, ",
      date_afternoon: "Добрый день, ",
      date_evening: "Добрый вечер, ",
      date_night: "Доброй ночи, ",
    },
    error: {
      serverError: "Ошибка сервера",
    },
    forms: {
      signIn: "Вход",
      signUp: "Регистрация",
      password: "Пароль",
      postSweetPh: "Начните писать",
      post: "Опубликовать",
      name: "Имя",
      avatarUrl: "Ссылка на аватар (не обязательно)",
    },
    profile: {
      email: "E-mail адрес:",
      regDate: "Дата регистрации:",
      date: "{{date, DATE_HUGE}}",
    },
  },
};

export default ru;

import {
  SWEETS_REQUEST,
  SWEETS_REQUEST_SUCCESS,
  SWEETS_REQUEST_FAILED,
  POST_SWEET_REQUEST,
  POST_SWEET_SUCCESS,
  POST_SWEET_FAILED,
  DELETE_SWEET_REQUEST,
  DELETE_SWEET_SUCCESS,
  DELETE_SWEET_FAILED,
  TOGGLE_STAR_SUCCESS,
} from "../constants/sweets";
import ISweet from "../../types/sweet";
import { TSweetsActions } from "../actions/sweets";

export type TSweetsState = {
  sweets: ISweet[];
  sweetsRequest: boolean;
  sweetsSuccess: boolean;
  sweetsFailed: boolean;
  postSweetRequest: boolean;
  postSweetSuccess: boolean;
  postSweetFailed: boolean;
  deleteRequest: boolean;
  deletingSweet?: string;
};

const initialState: TSweetsState = {
  sweets: [],
  sweetsRequest: false,
  sweetsSuccess: false,
  sweetsFailed: false,
  postSweetRequest: false,
  postSweetSuccess: false,
  postSweetFailed: false,
  deleteRequest: false,
  deletingSweet: undefined,
};

export const sweetsReducer = (
  state = initialState,
  action: TSweetsActions
): TSweetsState => {
  switch (action.type) {
    case SWEETS_REQUEST: {
      return {
        ...state,
        sweetsRequest: true,
      };
    }
    case SWEETS_REQUEST_SUCCESS: {
      return {
        ...state,
        sweets: action.sweets,
        sweetsFailed: false,
        sweetsRequest: false,
        sweetsSuccess: true,
      };
    }
    case SWEETS_REQUEST_FAILED: {
      return { ...state, sweetsFailed: true, sweetsRequest: false };
    }
    case POST_SWEET_REQUEST: {
      return {
        ...state,
        postSweetRequest: true,
        postSweetSuccess: false,
      };
    }
    case POST_SWEET_SUCCESS: {
      return {
        ...state,
        sweets: [action.sweet, ...state.sweets],
        postSweetFailed: false,
        postSweetRequest: false,
        postSweetSuccess: true,
      };
    }
    case POST_SWEET_FAILED: {
      return { ...state, postSweetFailed: true, postSweetRequest: false };
    }
    case DELETE_SWEET_REQUEST: {
      return {
        ...state,
        deleteRequest: true,
        deletingSweet: action.id,
      };
    }
    case DELETE_SWEET_SUCCESS: {
      return {
        ...state,
        sweets: state.sweets.filter((item) => item._id !== action.id),
        deleteRequest: false,
        deletingSweet: undefined,
      };
    }
    case DELETE_SWEET_FAILED: {
      return {
        ...state,
        deleteRequest: false,
        deletingSweet: undefined,
      };
    }
    case TOGGLE_STAR_SUCCESS: {
      return {
        ...state,
        sweets: state.sweets.map((s) =>
          s._id === action.sweet._id ? action.sweet : s
        ),
      };
    }
    default: {
      return state;
    }
  }
};

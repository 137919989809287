import { FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Preloader } from "../";
import { useDispatch, useSelector } from "../../services/hooks";
import { postSweetThunk } from "../../services/actions/sweets";
import IPostSweetFormProps from "./post-sweet-form.types";
import postFormStyles from "./post-sweet-form.module.css";

const PostSweetForm = ({ validation, isModal }: IPostSweetFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { postSweetRequest, postSweetSuccess } = useSelector(
    (state) => state.sweets
  );
  const { values, handleChange, resetForm, isValid } = validation;

  // Initial reset
  useEffect(() => {
    resetForm();
    return () => resetForm();
  }, [resetForm]);

  // Reset input after successful submit
  useEffect(() => {
    if (postSweetSuccess) {
      resetForm();
    }
  }, [postSweetSuccess, resetForm]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(postSweetThunk({ text: values.text || values.modalText }));
  };

  return (
    <form className={postFormStyles.form}>
      <textarea
        className={postFormStyles.textarea}
        minLength={2}
        maxLength={240}
        value={isModal ? values.modalText || "" : values.text || ""}
        placeholder={t("forms.postSweetPh")}
        name={isModal ? "modalText" : "text"}
        onChange={handleChange}
        required
      />
      <span className={postFormStyles.counter}>
        {values.text ? values.text.length : 0}/240
      </span>
      <Button
        className={postFormStyles.button}
        type="submit"
        onClick={handleSubmit}
        disabled={!isValid || postSweetRequest}
      >
        {postSweetRequest ? <Preloader /> : t("forms.post")}
      </Button>
    </form>
  );
};

export default PostSweetForm;

import cn from "classnames";
import Sweet from "../sweet/sweet";
import { useSelector } from "../../services/hooks";
import ISweetListProps from "./sweets-list.types";
import sweetsStyles from "./sweets-list.module.css";

function SweetsList({ data }: ISweetListProps) {
  const { isLoggedIn } = useSelector((state) => state.member);
  return (
    <ul
      className={cn(sweetsStyles.list, {
        [sweetsStyles.listSmall]: isLoggedIn,
      })}
    >
      {data.map((sweet) => (
        <li className={sweetsStyles.listItem} key={sweet._id}>
          <Sweet item={sweet} />
        </li>
      ))}
    </ul>
  );
}

export default SweetsList;

import cn from "classnames";
import { Button, Preloader } from "../";
import { useSelector } from "../../services/hooks";
import IFormProps from "./form.types";
import formStyles from "./form.module.css";

const Form = ({
  className,
  name,
  title,
  onSubmit,
  children,
  isValid,
  errors,
}: IFormProps) => {
  const { loginRequest, registerRequest } = useSelector(
    (state) => state.member
  );

  return (
    <form
      className={cn(formStyles.form, className)}
      name={name}
      onSubmit={onSubmit}
      method="GET"
    >
      <h2 className={formStyles.title}>{title}</h2>
      <fieldset className={formStyles.fields}>{children}</fieldset>
      <Button
        type="submit"
        disabled={!isValid || loginRequest || registerRequest}
        className={formStyles.button}
      >
        {loginRequest ? <Preloader /> : title}
      </Button>
      <span
        className={cn(formStyles.submitError, {
          [formStyles.submitErrorActive]: [errors.submit],
        })}
      >
        {errors.submit || ""}
      </span>
    </form>
  );
};

export default Form;

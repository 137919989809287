import { Redirect, Route } from "react-router-dom";
import { RouteProps } from "react-router";
import { useSelector } from "../../services/hooks";

const ProtectedRoute = ({ children, ...rest }: RouteProps) => {
  const { isLoggedIn, isMemberLoaded } = useSelector((state) => state.member);

  if (!isMemberLoaded) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn ? children : <Redirect to={{ pathname: "/" }} />
      }
    />
  );
};

export default ProtectedRoute;

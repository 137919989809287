import { combineReducers } from "redux";
import { membersReducer } from "./member";
import { headerReducer } from "./header";
import { sweetsReducer } from "./sweets";

export const rootReducer = combineReducers({
  member: membersReducer,
  header: headerReducer,
  sweets: sweetsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
